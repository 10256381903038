/* You can add global styles to this file, and also import other style files */

@import 'theme-variables';
@import 'common-styles';

$assetPath: '/assets/images';
$fontPath: '/assets/fonts';

@font-face {
    font-family: 'Roboto';
    src: url('#{$fontPath}/Roboto-Regular.eot');
    src: url('#{$fontPath}/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}/Roboto-Regular.woff2') format('woff2'),
    url('#{$fontPath}/Roboto-Regular.woff') format('woff'),
    url('#{$fontPath}/Roboto-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$fontPath}/Roboto-Medium.eot');
    src: url('#{$fontPath}/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}/Roboto-Medium.woff2') format('woff2'),
    url('#{$fontPath}/Roboto-Medium.woff') format('woff'),
    url('#{$fontPath}/Roboto-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Roboto';
    src: url('#{$fontPath}/Roboto-Bold.eot');
    src: url('#{$fontPath}/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
    url('#{$fontPath}/Roboto-Bold.woff2') format('woff2'),
    url('#{$fontPath}/Roboto-Bold.woff') format('woff'),
    url('#{$fontPath}/Roboto-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

html,
body {
    width: 100%;
    height: 100%;
    background: $white-color;
    outline: none !important;
    font-size: 14px;
    font-weight: 400;
    font-family: 'Roboto';
    position: relative;
    margin: 0;
    padding: 0;
    letter-spacing: 0.5px;
    overflow-x: hidden;
    overflow-y: hidden;
}

ul {
    margin: 0;
    padding: 0;

    li {
        list-style: none;
    }
}

.leadspeed-app,
.cdk-overlay-container,
.cdk-global-overlay-wrapper {

    .row {
        margin: 0;
    }

    .row>* {
        padding-right: 10px;
        padding-left: 10px;
    }

    .form-field {
        width: 100%;
        position: relative;
        margin-bottom: 20px;
        display: inline-block;

        .label-text {
            color: $title-text-dark-color;
            font-size: 13px;
            font-weight: 400;
            margin-bottom: 5px;
        }

    }

    .display-1,
    .display-2,
    .display-3,
    .display-4,
    .display-5,
    .display-6 {
        color: $title-text-color;
        font-weight: 700;
        font-style: normal;
    }

    .display-1 {
        font-size: 26px;
    }

    .display-2 {
        font-size: 24px;
    }

    .display-3 {
        font-size: 22px;
    }

    .display-4 {
        font-size: 20px;
    }

    .display-5 {
        font-size: 18px;
    }

    .display-6 {
        font-size: 16px;
    }

    sup {
        color: $error-field-color;
    }

    .page-title-wrapper {
        width: 100%;
        margin-bottom: 17px;

        .button-wrapper {

            .mat-button-base {
                margin-left: 20px;
                text-transform: capitalize;
            }

            .service-report-wrapper, .lead-report-wrapper {

                .mat-button-base {
                    margin-left: 10px;
                }

            }

        }

    }

    .common-wrapper {
        width: 100%;
        height: 100%;

        .page-title-wrapper {

            .button-wrapper {
                display: inherit;
            }

        }

        .kendo-grid-wrapper {
            width: 100%;
            height: calc(100% - 57px);

            .k-grid {
                height: 100%;
            }

        }

    }

    .icon-button-wrapper {

        .mat-button-base {
            margin-left: 20px;
            @include button-style(38px, 38px, 0, 5px);

            img {
                @include image-primary-color();
            }

            &.mat-primary,
            &.mat-error {

                img {
                    @include image-white-color();
                }

            }

            &.error-color {

                img {
                    @include image-error-color();
                }

            }

            &.padding-btn {
                padding: 0 20px;
                border-radius: 0;
            }

        }

    }

    .details-box-wrapper {
        width: 100%;
        margin-bottom: 24px;
        border-radius: 10px;
        border: 1px solid $border-color;

        .display-6 {
            padding: 12px 15px;
            border-bottom: 1px solid $border-color;

            &.table-title {
                padding: 12px 0;
                border: none;
            }

        }

        .text-wrapper {
            padding: 5px 5px;

            .text-content {
                padding: 10px 0;
                overflow-wrap: break-word;

                .text {
                    color: $text-dark-color;
                    font-size: 14px;
                    margin-bottom: 4px;
                }

                .label {
                    color: $text-color;
                    font-size: 13px;
                }

            }

        }

        // .custom-table {
        //     width: 100%;
        //     margin-bottom: 24px;

        //     th {
        //         color: $grid-header-text-color;
        //         font-size: 13px;
        //         font-weight: 500;
        //         padding: 9px 15px;
        //         background-color: $input-field-bg-color;
        //     }

        //     td {
        //         color: $text-dark-color;
        //         font-size: 14px;
        //         padding: 9px 15px;
        //         overflow-wrap: break-word;
        //     }

        //     .no-record-text {
        //         width: 100%;
        //         font-size: 12px;
        //         text-align: center;
        //     }

        // }

    }

    .column-options-wrapper {
        min-width: 190px !important;
        padding: 10px 15px;
        overflow: auto;
        scrollbar-width: none;

        ul {
            max-height: 210px;
            @include scroll-style();

            li {
                width: 100%;
                padding-right: 15px;
                margin-bottom: 8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

        }

        .mat-checkbox {

            .mat-checkbox-frame {
                border-width: 1px;
                border-color: $text-thin-color;
                opacity: 1;
            }

            .mat-checkbox-layout {

                .mat-checkbox-label {
                    color: $grid-header-text-color;
                    font-size: 14px;
                    font-weight: 600;
                }

            }

            &.mat-checkbox-checked.mat-primary {

                .mat-checkbox-background {
                    background: $primary-color;
                }

            }

        }

        .mat-menu-content:not(:empty) {
            padding: 0;
        }

        &::-webkit-scrollbar {
            display: none;
        }

    }

    .header-menu-wrapper {
        border-radius: 0;

        .mat-menu-content:not(:empty) {
            padding: 0;
        }

        button.mat-menu-item {
            color: $text-color;
            padding: 0 20px;
            letter-spacing: 0.5px;

            img {
                margin-right: 10px;
                @include image-gray-color();
            }

            &:hover {
                color: $title-text-color;
                background-color: $grid-header-color;

                img {
                    @include image-active-color();
                }

            }

        }

        a.mat-menu-item {
            color: $text-color;
            padding: 0 20px;
            letter-spacing: 0.5px;

            img {
                margin-right: 10px;
                @include image-gray-color();
            }

            &:hover {
                color: $title-text-color;
                background-color: $grid-header-color;

                img {
                    @include image-active-color();
                }

            }
        }

    }

    .notification-menu-wrapper {
        max-width: 350px;
        border-radius: 0;

        .mat-menu-content:not(:empty) {
            padding: 0;
        }

        .title-text {
            width: 100%;
            color: $title-text-medium-color;
            font-size: 16px;
            font-weight: 500;
            padding: 10px 15px;
            border-bottom: 1px solid $border-color;
        }

    }

    .comments-wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        .comments-content-wrapper {
            width: 100%;
            min-height: 100px;
            height: 100%;
            padding: 20px;
            position: relative;
            @include scroll-style();

            .heading-text {
                color: $title-text-color;
                font-size: 18px;
                font-weight: 700;
                margin-bottom: 15px;
            }

            .profile-wrapper {
                width: 100%;
                position: relative;

                img {
                    width: 32px;
                    height: 32px;
                    top: 0;
                    left: 0;
                    position: absolute;
                    border-radius: 50%;
                }

                .title-text,
                .date-text {
                    width: 100%;
                    padding-left: 42px;
                }

                .title-text {
                    color: $title-text-color;
                    font-size: 14px;
                    font-weight: bold;
                }

                .date-text {
                    color: $text-color;
                    font-size: 10px;
                    font-weight: 400;
                }

            }

            .text {
                color: $text-color;
                font-size: 13px;
                margin: 14px 0;
            }

            .text-sm {
                color: $black-color;
                font-size: 12px;
            }

        }

    }

    .filter-wrapper {
        width: 100%;
        padding: 15px;
        margin-bottom: 5px;
        position: relative;
        background-color: $input-field-bg-color;

        .k-textbox,
        .k-multiselect,
        .k-dropdownlist,
        .k-textarea,
        .k-datepicker,
        .k-datetimepicker {

            &.k-input-solid,
            &.k-picker-solid {
                background-color: $white-color;
            }

            .icon-wrapper {

                img {
                    @include image-input-placeholder-color();
                }

            }
        }

        .k-textbox {

            &.k-input-solid,
            &.k-picker-solid {
                background-color: $white-color;

                .k-input-inner {
                    padding: 11px 16px 11px 0;
                }

            }

        }

        .filter-chip-list-wrapper {
            display: inline-block;
        }

        .mat-button-base {
            @include button-style(40px, 40px, 0, 50%);
        }

        .close-btn {
            top: 50%;
            right: 0;
            position: absolute;
            transform: translate(-50%, -50%);

            img {
                @include image-error-color();
            }
        }

    }

    .expansion-wrapper {
        width: $expansion-wrapper;
        height: 100%;
        position: absolute;
        top: 0;
        right: ($expansion-header-wrapper - $expansion-wrapper);
        z-index: 1000;
        background-color: $white-color;
        box-shadow: 0px 0px 10px #00000029;
        transition: 0.5s;

        .expansion-header-wrapper {
            width: $expansion-header-wrapper;
            height: 100%;
            float: left;

            .mat-button {
                min-width: 100%;
                height: 50px;
                line-height: 50px;
                border-radius: 0;
                margin-bottom: 5px;

                img.time-icon {
                    right: 12px;
                    bottom: 12px;
                    position: absolute;
                }

                &.active {

                    img {
                        @include image-primary-color();
                    }

                }

            }

        }

        .expansion-content-wrapper {
            width: ($expansion-wrapper - $expansion-header-wrapper);
            height: 100%;
            float: left;
            border-left: 1px solid $border-color;
        }

        .tab-group-wrapper {
            width: 100%;
            height: 100%;

            .mat-tab-label {
                min-width: 33.333%;

                &.mat-tab-label-active {
                    color: $primary-color;
                }

            }

            .mat-tab-body-wrapper {
                height: calc(100% - $expansion-header-wrapper);
            }

            .mat-ink-bar {
                background-color: $primary-color;
            }

            .mat-tab-body-content {
                overflow: hidden;
            }

            &.document-tab-wrapper {

                .mat-tab-label {
                    min-width: 25%;
                }

            }

        }

        .expand-arrow-icon {
            transform: rotate(180deg);
        }

        &.expanded {
            right: 0;

            .expand-arrow-icon {
                transform: rotate(0deg);
            }

        }

    }
 
    .lead-tab-wrapper {

        .mat-tab-label.mat-tab-label {
            width: 50%;
            height: 40px;
            border: 1px solid $mat-tab-border-color;
            border-bottom: 0;
            opacity: 1;
            color: $mat-tab-label-color;
            background: $input-field-bg-color;
        }

        .mat-tab-label.mat-tab-label-active {
            color: $primary-color;
            background: $mat-tab-background-color;
            border-width: 0;
        }

        .mat-ink-bar {
            background-color: $primary-color;
        }

    }

    .filter-chip-list-wrapper {

        .mat-chip-list-wrapper {
            margin: 0;
            justify-content: flex-end;

            .mat-standard-chip {
                min-height: 28px;
                color: $title-text-dark-color;
                font-size: 13px;
                font-weight: 500;
                padding: 0 20px;
                margin: 0 7px;
                background: transparent;
                cursor: pointer;
                box-shadow: none;
                text-transform: uppercase;

                &.mat-chip-selected {
                    color: $white-color;

                    &.mat-primary {
                        background-color: $primary-color;
                    }

                    &.mat-warn {
                        background-color: $warning-color;
                    }

                    &.mat-success {
                        background-color: $success-color;
                    }

                    &.mat-error {
                        background-color: $error-color;
                    }

                    &.mat-dark {
                        background-color: $grid-header-text-color;
                    }

                }

                &:last-child {
                    margin-right: 0;
                }

            }

        }

    }

    .profile-image-wrapper {
        width: 130px;
        height: 130px;
        top: 42px;
        left: 0;
        right: 0;
        margin: auto;
        position: absolute;
        border-radius: 50%;
        border: 7px solid $white-color;
        z-index: 100;
        overflow: hidden;

        .overlay-wrapper {
            width: 100%;
            height: 100%;
            position: relative;
            cursor: pointer;

            .profile-image {
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                position: relative;
            }

            .overlay-content {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                position: absolute;
                background: rgba($color: #000000, $alpha: 0.4);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                visibility: hidden;

                .camera-input-file::-webkit-file-upload-button {
                    visibility: hidden;
                }

            }

            &:hover .overlay-content {
                visibility: visible;
            }

        }

    }

    .status-btn {
        font-size: 11px;
        font-weight: 500;
        border-radius: 25px;
        padding: 7px 18px;
        text-transform: uppercase;
        line-height: normal;
    }

    .status-circle-wrapper {
        width: 100%;
        display: flex;
        align-items: center;

        .circle-status {
            width: 10px;
            height: 10px;
            margin-right: 5px;
            border-radius: 50%;
        }

    }

    .file-wrapper {
        width: 100%;
        position: relative;
        margin-bottom: 20px;

        a {
            width: 100%;
            float: left;
            color: $text-dark-color;
            font-size: 14px;
            font-weight: 400;
            padding: 10px 12px;
            margin-bottom: 5px;
            background-color: $input-field-bg-color;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }

        }

    }

    .success-color {
        @include success-color();
    }

    .warning-color {
        @include warning-color();
    }

    .error-color {
        @include error-color();
    }

    .black-color-text {
        color: $black-color;
    }

    .error-text {
        color: $error-field-color;
        font-size: 13px;
        font-weight: 500;
        padding: 2px 0;
    }

    .custom-grid-filter-wrapper {
        display: flex;
        align-items: center;
        justify-content: end;
        position: relative;
        margin-right: 20px;
    }

    .custom-option-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        position: relative;

        .option-text {
            width: 100%;
            position: relative;
            padding: 0 25px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .default-icon,
        .delete-icon {
            position: absolute;
            z-index: 100;
            cursor: pointer;
        }

        .default-icon {
            left: 0;

            img {
                @include image-gray-color();

                &.active {
                    @include image-warning-color();
                }

            }

        }

        .delete-icon {
            right: 0;

            img {
                @include image-input-placeholder-color();

                &.disabled-img {
                    opacity: 0.6;
                    pointer-events: none;
                    cursor: not-allowed;
                }

            }

        }

    }

    .overlay-loader {
        width: 100%;
        height: 100%;
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.80);
        z-index: 99999;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        flex-direction: column;

        .text {
            color: $title-text-color;
            margin: 10px 0;
            font-size: 14px;
            font-weight: 600;
        }

    }

    .form-loader,
    .form-loader-sm,
    .button-loader-sm {
        border-radius: 50%;
        -webkit-animation: rotation .8s linear infinite;
        -ms-animation: rotation .8s linear infinite;
        animation: rotation .8s linear infinite;
        position: relative;
    }

    .form-loader {
        width: 5rem;
        height: 5rem;
        border-top: 3px solid $title-text-color;
        border-right: 3px solid transparent;
    }

    .form-loader-sm {
        width: 2rem;
        height: 2rem;
        border-top: 3px solid $title-text-color;
        border-right: 3px solid transparent;
    }

    .button-loader-sm {
        width: 2rem;
        height: 2rem;
        border-top: 2px solid $title-text-color;
        border-right: 2px solid transparent;
    }

    @-webkit-keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    @keyframes rotation {
        from {
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
        }

        to {
            -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

}

.custom-input-file {
    width: 100%;
    padding: 12px;
    border: 1px solid $border-color;
    background-color: $input-field-bg-color;
    color: $text-color;

    .input-file {
        width: 100%;
    }

    input[type=file]::file-selector-button {
        color: $title-text-dark-color;
        margin-right: 20px;
        padding: 8px 16px;
        border-radius: 0;
        border: 1px solid $title-text-dark-color;
        background: $white-color;
        cursor: pointer;

        &:hover {
            color: $white-color;
            background: $title-text-dark-color;
        }

    }

    &.error-field {
        border-color: $error-field-color;
    }

}

.text-color-black {
    color: $black-color !important;
}

.no-record-text {
    width: 100%;
    font-size: 12px;
    text-align: center;
}

.text-underline-hover:hover {
    text-decoration: underline;
}

.image-icon-primary {
    cursor: pointer;
    @include image-primary-color();
}

.border-none {
    border: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    font-size: 14px;
    border: none;
    background: transparent;
    box-shadow: 0 0 0px 1000px initial inset;
    -webkit-text-fill-color: #33383E;
    -webkit-box-shadow: 0 0 0px 1000px initial inset;
    transition: background-color 5000s ease-in-out 0s;
}

.disable-click {
    opacity: 0.55;
    pointer-events: none;
    cursor: not-allowed;
}

mat-hint {
    font-size: 13px;
}

.cdk-global-overlay-wrapper .cdk-overlay-pane.associated_contact, 
.cdk-global-overlay-wrapper .cdk-overlay-pane.associated_company,
.cdk-global-overlay-wrapper .cdk-overlay-pane.announcement_modal {
    width: 650px;
}

.announcement-editor-content {

    .title-wrapper {

        .k-input-prefix {
            border-right: 1px solid $border-color;
            background: $details-bg-color;
        }
    }

    .kendo-editor-wrapper {

        kendo-toolbar {
            background: $grid-header-color;
            box-shadow: 0 0 0 0;

            .k-icon-button {
                background: transparent;
                border: 0;
            }
        }
    }
}

.associate-table {
    margin: 0;
    border-width: 0;
    border-collapse: separate;
    border-spacing: 0;
    empty-cells: show;
    outline: 0;
    overflow-x: auto;
    border-color: transparent;

    .wrap-contact-company {
        width: 150px;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    thead {
        color: $grid-header-text-color;
        background-color: $grid-header-color;

        .table-header th {
            padding: 12px 24px;
            font-size: 13px;
            font-weight: 700;
            border-color: transparent;
        }

    }

    tbody {
        .primary td {
            font-weight: bold !important;
        }

        tr:nth-child(even) {
            background-color: $input-field-bg-color;
        }

        td {
            color: $title-text-dark-color;
            font-size: 13px;
            font-weight: 400;
            border-color: transparent;
            white-space: nowrap;
            padding: 12px 24px;
        }
    }

    .grid-icon-btn-wrapper {
        position: relative;
        z-index: 100;

        .mat-flat-button {
            min-width: 32px;
            height: 32px;
            line-height: 32px;
            font-size: 14px;
            font-weight: 500;
            padding: 0 0;
            border-radius: 50%;
            letter-spacing: 0.5px;
            box-shadow: none;
        }
    }
}

.lead-edit-summary-status {
    padding: 12px 15px;
    border-bottom: 1px solid $border-color;

    .icon-button-wrapper {
        cursor: pointer;
        img {
            @include image-primary-color();
        }
    }

    .summary-title,
    .status-title {
        font-size: 16px;
        color: $title-text-color;
        font-weight: 700;
        font-style: normal;
        line-height: 1.2;
    }
}

.company-dropdown {
    border: 1px solid $border-color;
    color: $text-dark-color;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
    border-radius: 0;
    background-color: $input-field-bg-color;
    padding: 11px 16px;
    outline: none;
}

.empty {
    border: 1px solid $error-field-color !important;
}

.navigate-company-link {
    color: $title-text-color;
    font-weight: 600;
    cursor: pointer;
}