@import 'theme-variables';
@import 'common-styles';

$assetPath: '/assets/images';

.toast-container {

    .ngx-toastr {
        width: 500px;
        background: $white-color;
        border-radius: 12px;
        border-left: 12px solid;
        padding: 12px 45px;
        display: flex;
        align-items: center;

        .toast-progress {
            opacity: 1;
        }

        &.toast-success,
        &.toast-error,
        &.toast-warning,
        &.toast-info {
            background-size: 20px;
            background-repeat: no-repeat;
            background-position: 12px 50%;
        }

        &.toast-success {
            border-color: $success-color;
            background-image: url('#{$assetPath}/toastr-success-icon.svg');

            .toast-progress {
                background-color: $success-color;
            }
        }

        &.toast-error {
            border-color: $error-color;
            background-image: url('#{$assetPath}/toastr-error-icon.svg');

            .toast-progress {
                background-color: $error-color;
            }
        }

        &.toast-warning {
            border-color: $warning-color;
            background-image: url('#{$assetPath}/toastr-warning-icon.svg');

            .toast-progress {
                background-color: $warning-color;
            }
        }

        &.toast-info {
            border-color: $primary-color;

            .toast-progress {
                background-color: $primary-color;
            }
        }

        .toast-message {
            color: $title-text-color;
            font-weight: 500;
        }

        .toast-close-button {
            width: 21px;
            height: 21px;
            top: 50%;
            right: 12px;
            position: absolute;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            background-image: url('#{$assetPath}/toastr-close-icon.svg');
            background-size: 21px;
            background-repeat: no-repeat;
            z-index: 1000;

            &:hover {
                opacity: 1;
            }

            span {
                display: none;
            }
        }

    }

}

// Mobile View

@media only screen and (max-width: 600px) {

    .toast-container {

        .ngx-toastr {
            width: 94vw;
        }
    }

}