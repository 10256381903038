@import 'theme-variables';

@mixin scroll-style() {
    overflow: auto;
    scrollbar-width: thin;
    scroll-behavior: smooth;
    scrollbar-color: $scroll-thumb-color transparent;

    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
        background-color: $grid-drag-column-color;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: $scroll-thumb-color;
    }

}

@mixin scroll-visiblity-none-style() {
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin button-style($min-width: 150px, $height: 38px, $padding: 20px, $radius: 0, $font-size: 14px) {
    min-width: $min-width;
    height: $height;
    line-height: $height;
    font-size: $font-size;
    font-weight: 500;
    padding: 0 $padding;
    border-radius: $radius;
    letter-spacing: 0.5px;
    box-shadow: none;
}

@mixin primary-color() {
    color: $white-color;
    background-color: $primary-color;
}

@mixin secondary-color() {
    color: $black-color;
    background-color: $secondary-color;
}

@mixin success-color() {
    color: $white-color;
    background: $success-color;
}

@mixin warning-color() {
    color: $white-color;
    background: $warning-color;
}

@mixin error-color() {
    color: $white-color;
    background: $error-color;
}

@mixin input-color() {
    color: $white-color;
    background: $input-field-bg-color;
}

// Page Wrapper
@mixin page-wrapper($height: 0px, $paddingLeft: 10px, $top: 0) {
    width: 100%;
    height: calc(100% - $height);
    position: relative;
    top: $top;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 $paddingLeft 0 $paddingLeft;
    scrollbar-width: thin;
    scrollbar-color: $scroll-thumb-color transparent;
    scroll-behavior: smooth;

    &.page-content-center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &::-webkit-scrollbar {
        width: 5px;
        height: 8px;
        background-color: $white-color;
    }

    &::-webkit-scrollbar-thumb {
        background-color: $scroll-thumb-color;
    }

}

@mixin box-content ($width: 100%, $padding: 25px) {
    width: $width;
    padding: 10px $padding $padding;
    float: left;
    text-align: start;

    .text {
        color: $text-color;
        font-size: 14px;
    }

    .link-text {
        color: $primary-color;
        font-size: 14px;
        font-weight: 700;
    }
}

@mixin box-shadow {
    -webkit-box-shadow: 0px 10px 30px #00000028;
    -moz-box-shadow: 0px 10px 30px #00000028;
    box-shadow: 0px 10px 30px #00000028;
}

@mixin image-primary-color {
    filter: invert(58%) sepia(59%) saturate(601%) hue-rotate(153deg) brightness(98%) contrast(82%);
}

@mixin image-warning-color {
    filter: brightness(0) saturate(100%) invert(61%) sepia(93%) saturate(401%) hue-rotate(357deg) brightness(94%) contrast(99%);
}

@mixin image-active-color {
    filter: brightness(0) saturate(100%) invert(23%) sepia(74%) saturate(1684%) hue-rotate(179deg) brightness(89%) contrast(107%);
}

@mixin image-active-dark-color {
    filter: brightness(0) saturate(100%) invert(10%) sepia(68%) saturate(1797%) hue-rotate(180deg) brightness(93%) contrast(99%);
}

@mixin image-gray-color {
    filter: invert(79%) sepia(0%) saturate(5%) hue-rotate(189deg) brightness(80%) contrast(82%);
}

@mixin image-white-color {
    filter: brightness(0) saturate(100%) invert(99%) sepia(51%) saturate(0%) hue-rotate(198deg) brightness(106%) contrast(100%);
}

@mixin image-black-color {
    filter: brightness(0) saturate(100%);
}

@mixin image-success-color {
    filter: brightness(0) saturate(100%) invert(63%) sepia(9%) saturate(5881%) hue-rotate(102deg) brightness(115%) contrast(52%);
}

@mixin image-error-color {
    filter: brightness(0) saturate(100%) invert(67%) sepia(10%) saturate(2688%) hue-rotate(312deg) brightness(104%) contrast(106%);
}

@mixin image-input-bg-color {
    filter: brightness(0) saturate(100%) invert(100%) sepia(15%) saturate(760%) hue-rotate(175deg) brightness(100%) contrast(98%);
}

@mixin image-input-text-color {
    filter: brightness(0) saturate(100%) invert(7%) sepia(36%) saturate(1471%) hue-rotate(167deg) brightness(93%) contrast(101%);
}

@mixin image-dark-color {
    filter: brightness(0) saturate(100%) invert(8%) sepia(52%) saturate(3384%) hue-rotate(185deg) brightness(99%) contrast(99%);
}

@mixin image-input-placeholder-color {
    filter: brightness(0) saturate(100%) invert(46%) sepia(8%) saturate(84%) hue-rotate(155deg) brightness(95%) contrast(91%);
}

@mixin image-error-dark-color {
    filter: brightness(0) saturate(100%) invert(17%) sepia(100%) saturate(6782%) hue-rotate(1deg) brightness(110%) contrast(117%);
}