@import 'theme-variables';
@import 'common-styles';

// -----------------------------------Kendo Grid------------------------------------------------------------

.kendo-grid-wrapper {
    position: relative;

    .k-grid {
        font-family: 'Roboto';
        border: none;

        td {
            color: $title-text-dark-color;
            font-size: 13px;
            font-weight: 400;
            border-color: transparent;
            white-space: nowrap;
        }

        thead {
            color: $grid-header-text-color;
            background-color: $grid-header-color;

            th {
                padding: 8px 24px;
                font-size: 13px;
                font-weight: 700;
                border-color: transparent;

                .k-link {

                    .k-icon {
                        color: $grid-header-text-color;
                        font-weight: 700;
                    }

                    &:hover {
                        color: inherit;
                    }

                }

            }

        }

        .k-grid-header {
            padding: 0 7px 0 0 !important;
            border-color: transparent;
            background-color: $grid-header-color;
        }

        .k-grid-header-wrap {
            border-color: transparent;
        }

        .k-grouping-header {
            border-color: transparent;
            background-color: $grid-group-header-color;
        }

        tbody {

            .lds_updated {
                background: $lds_updated_color !important;
            }

            tr {

                &.k-disabled {
                    background-color: $deactivated-color !important;
                    opacity: 1;
                    cursor: default;

                    .mat-button-base {
                        opacity: 0.5;
                    }

                    td {
                        color: $deactivated-text-color;
                    }

                }

                &.disabled-checkbox-column {

                    .k-checkbox {
                        pointer-events: none;
                        border-color: rgba(0, 0, 0, 0.30);
                    }

                }

                &.k-deactivate-row {
                    background: $deactivated-color !important;

                    td {
                        color: $deactivated-text-color;
                    }

                }

                &:nth-child(even) {
                    background-color: $input-field-bg-color;
                }

            }

        }

        .k-grid-content {
            @include scroll-style();
        }

        .k-indicator-container {
            color: $black-color;
            font-size: 13px;
            font-weight: 500;
        }

        .k-grid-pager {
            // padding-bottom: 0;
            border-color: transparent;
        }

        .k-pager-numbers {

            .k-link {
                color: $grid-page-number-color;
                font-weight: 500;

                &.k-selected {
                    color: $white-color;
                    background-color: $title-text-color;
                }

            }

        }

        .k-pager-info {
            font-size: 14px;
            font-weight: 500;
        }

        .k-hierarchy-cell {

            .k-icon {
                color: $title-text-color;
                padding: 12px;
            }

        }

        .selection-column {
            width: 40px;
            min-height: 43px;
            top: 2px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .navigate-link {
            color: $title-text-color;
            font-weight: 600;
            text-decoration: underline;
            cursor: pointer;
        }

        .basic-grid-btn {
            width: 100%;
            color: $title-text-color;
            font-weight: 600;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

        }

        .basic-grid-btn-ellipsis {
            width: 100%;
            color: $title-text-color;
            font-weight: 600;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            cursor: pointer;

            &.font-normal {
                color: $title-text-dark-color;
                font-weight: 400;
            }

            &:hover {
                text-decoration: underline;
            }

        }

        .grid-progress-wrapper {
            width: 100%;
            height: 24px;
            position: relative;
            background-color: $no-record-found-color;

            span {
                height: 100%;
                font-size: 13px;
                top: 0;
                left: 0;
                position: absolute;
                display: flex;
                align-items: center;
            }

        }

        .grid-btn {
            position: relative;
            z-index: 100;

            .mat-flat-button {
                @include button-style(auto, 34px, 24px, 4px, 13px);
            }

        }

        .grid-icon-btn-wrapper {
            position: relative;
            z-index: 100;

            .mat-flat-button {
                @include button-style(32px, 32px, 0, 50%);

                img {
                    @include image-white-color();
                }

                &:last-child {
                    margin: 0 10px;
                }

            }

        }

        .overlay-loader {
            background-color: rgba(255, 255, 255, 0.6);
        }

        .k-grid-header-sticky {
            color: $grid-header-text-color !important;
            background-color: $grid-header-color !important;
        }
        
        .k-grid-content-sticky {
                background-color: $input-field-bg-color !important;
        }

    }

    .nested-kendo-grid-wrapper {
        position: relative;

        .k-grid {
            font-family: 'Roboto';
            border: none;

            thead {
                color: $white-color;
                background-color: $nested-grid-header-color;

                th {
                    padding: 8px 24px;
                    font-size: 13px;
                    font-weight: 500;
                    border-color: transparent;

                    .k-link {

                        .k-icon {
                            color: $white-color;
                            font-weight: 700;
                        }

                    }

                }

            }

            .k-grid-header {
                padding: 0 7px 0 0 !important;
                border-color: transparent;
                background-color: $nested-grid-header-color;
            }

            .k-grid-content {
                min-height: 50px;
                height: auto !important;
            }

        }

    }

    .table-wrapper {
        height: 100%;
    }

}

// -----------------------------------End-------------------------------------------------------------------


.leadspeed-app,
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    font-family: 'Roboto';

    // -----------------------------------Kendo Common Style------------------------------------------------

    .k-textbox,
    .k-multiselect,
    .k-dropdownlist,
    .k-textarea,
    .k-datepicker,
    .k-datetimepicker {

        &.k-input-solid,
        &.k-picker-solid {
            color: $text-dark-color;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.5px;
            border-width: 1px;
            border-color: $border-color !important;
            border-radius: 0;
            background-color: $input-field-bg-color;

            .k-input-inner {
                letter-spacing: 0.5px;
                padding: 11px 16px;
            }

            .k-icon-button {
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .k-input-prefix,
            .k-input-suffix {
                .icon-wrapper {
                    width: 40px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .text-wrapper {
                    width: 80px;
                    height: 100%;
                    color: $text-color;
                    font-size: 13px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: $border-color;
                }

            }

        }

        .k-chip-md {
            font-family: 'Roboto';
            font-size: 13px;
            color: $text-dark-color;
            padding: 5px;
            border-radius: 0;
        }

        &.ng-touched.ng-invalid {
            border-color: $error-field-color !important;
        }

        &.k-focus {
            border-color: $primary-color !important;

            &::after {
                transform: scaleX(0);
            }

        }

        &.k-disabled,
        &.disable-input {
            cursor: not-allowed;
            background-color: $border-color;
        }

    }

    .k-calendar {

        .k-calendar-td.k-selected {

            .k-link {
                border-color: $primary-color;
                background-color: $primary-color;
            }

        }

    }

    .k-popup .k-action-buttons {

        .k-button-solid-primary {
            border-color: $primary-color;
            background-color: $primary-color;
        }

    }

    .grid-text-box {

        &.k-input-solid,
        &.k-picker-solid {
            font-size: 13px;
            background-color: $white-color;

            .k-input-inner {
                padding: 8px 12px;
            }
        }
    }

    form {

        &.ng-submitted {

            .k-input-solid.ng-untouched.ng-invalid,
            .k-picker-solid.ng-untouched.ng-invalid {
                border-color: $error-field-color !important;
            }

        }
    }



    // -----------------------------------End----------------------------------------------------------------

    // -----------------------------------Kendo Dropdown-----------------------------------------------------

    kendo-list {

        &.k-list {

            .k-list-content {
                @include scroll-style();
            }

            .k-list-item {
                padding: 10px 16px;

                &.k-selected {
                    color: $text-dark-color;
                    background-color: $grid-header-color;
                }

            }

        }

    }

    .k-animation-container {

        .k-list-optionlabel {
            gap: 0;
            padding: 10px 16px;
            color: $text-color;

            &:hover,
            &.k-selected {
                color: $text-color;
            }
        }

        .k-searchbox {

            .k-input-inner {
                padding: 8px 10px 8px 5px;
            }

            &.k-input-solid:focus-within {
                border-color: $title-text-dark-color;
            }

        }

    }

    // -----------------------------------End----------------------------------------------------------------

    // -----------------------------------Kendo Tooltip------------------------------------------------------

    .kendo-white-tooltip-wrapper {

        .k-popup {
            border: none;
            box-shadow: none;
        }

        .k-tooltip {
            padding: 0;
            background-color: $white-color;

            .heading-wrapper {
                width: 100%;
                padding: 10px 15px;
                color: $white-color;
                font-size: 14px;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: $primary-color;

                img {
                    @include image-white-color();
                }

            }

            .tooltip-wrapper {
                width: auto;
                color: $title-text-dark-color;
                font-size: 13px;
                font-weight: 500;
                padding: 7px 15px;
                background-color: $white-color;

                li {
                    line-height: 28px;
                }

            }

            .k-callout {
                color: $white-color;
            }

        }

    }

    // -----------------------------------End----------------------------------------------------------------

    // -----------------------------------Kendo Checkbox-----------------------------------------------------

    .k-checkbox:checked,
    .k-checkbox.k-checked {
        border-color: $title-text-color;
        background-color: $title-text-color;
    }

    // -----------------------------------End----------------------------------------------------------------


    // -----------------------------------Kendo Pages Dropdown ----------------------------------------------

    .page-size-dd .k-dropdown-wrap {
        border: none !important;
    }

    kendo-dropdownlist.page-size-dd {
        width: 95px !important;
        border: 1px solid $primary-color !important;
        padding: 0px;
        border-radius: 3px !important;
    }


    .k-switch-on {

        .k-switch-thumb {
            background-color: $white-color;
            height: 18px;
            width: 18px;
        }

        .k-switch-track {
            background-color: $primary-color;
            height: 23px;
            width: 38px;
        }

    }

    .k-switch-off {

        .k-switch-thumb {
            height: 18px;
            width: 18px;
            margin-left: 6px;
        }

        .k-switch-track {
            height: 23px;
            width: 38px;
        }

    }

    // -----------------------------------End----------------------------------------------------------------

}