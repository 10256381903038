@import 'theme-variables';
@import 'common-styles';

.mat-form-field,
.mat-expansion-panel-header,
.mat-expansion-panel-content,
.mat-select,
.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button,
.mat-table,
.mat-button-toggle,
.mat-menu-item,
.mat-checkbox,
.mat-tab-group,
.mat-tab-label,
.mat-tab-link,
.mat-option,
.mat-list-option,
.mat-radio-button,
.mat-tooltip {
    font-family: 'Roboto';
}

.leadspeed-app,
.cdk-overlay-container,
.cdk-global-overlay-wrapper {
    font-family: 'Roboto';

    // -----------------------------------Text Box--------------------------------------------------------------

    .mat-form-field {
        width: 100%;
        color: $text-dark-color;
        margin-top: 4px;

        .mat-input-element {
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.5px;
            margin: 0;

            &.text-area-input {
                min-height: 60px;
                resize: none;
                box-sizing: border-box;
            }

            &:disabled {
                color: $heading-text-color;
            }

        }

        .mat-form-field-infix {
            padding: 0 0 12px 0;
        }

        .mat-form-field-flex {
            padding: 0 16px;
            background-color: $input-field-bg-color;
        }

        .mat-form-field-outline {
            top: 0;
            color: $border-color;
        }

        .mat-form-field-wrapper {
            padding-bottom: 0;
            margin: 0;
        }

        .mat-form-field-underline {
            display: none;
        }

        .mat-form-field-outline-end,
        .mat-form-field-outline-start {
            border-radius: 0;
        }

        .mat-form-field-suffix {
            top: -2px;
            padding-left: 10px;

            .mat-icon {
                position: relative;
            }

        }

        .mat-error {
            color: $error-field-color;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.5px;
            display: flex;
            align-items: center;

            .error-text {
                padding-top: 3px;
            }

        }

        &.mat-form-field-disabled {

            .mat-form-field-label {
                color: $heading-text-color;
            }

            .mat-form-field-underline {
                background-color: $heading-text-color;
            }

        }

    }

    // End


    // -----------------------------------End-------------------------------------------------------------------


    // -----------------------------------Button----------------------------------------------------------------

    .mat-flat-button {
        @include button-style();

        &.btn-sm {
            @include button-style(110px, 38px, 20px, 0);
        }

        &.mat-primary {
            @include primary-color();
        }

        &.mat-secondary {
            @include secondary-color();
        }

        &.mat-success {
            @include success-color();
        }

        &.mat-warn {
            @include warning-color();
        }

        &.mat-error {
            @include error-color();
        }

        &.mat-input-color {
            @include input-color();
        }

        &.mat-button-disabled {
            cursor: not-allowed;
            opacity: 0.55;
        }

        &.icon-btn {
            margin-right: 10px;
            @include button-style(32px, 32px, 0, 50%);

            img {
                @include image-white-color();
            }

            &.mat-secondary {

                img {
                    @include image-dark-color();
                }

            }

            &:last-child {
                margin-right: 0;
            }

        }

    }

    .mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
        line-height: 38px;
    }

    // ------------------------------------End------------------------------------------------------------------


    // -----------------------------------Checkbox--------------------------------------------------------------

    .mat-checkbox {

        .mat-checkbox-layout {

            .mat-checkbox-label {
                line-height: normal;
            }

        }

        .mat-checkbox-inner-container {
            width: 17px;
            height: 17px;
        }

        .mat-checkbox-frame {
            border-width: 1px;
            border-color: $text-thin-color;
            opacity: 1;
        }

        &.mat-checkbox-checked.mat-primary {

            .mat-checkbox-background {
                background: $primary-color;
            }

        }

        &.mat-checkbox-checked.mat-accent {

            .mat-checkbox-background {
                background: $title-text-dark-color;
            }

        }

        &.mat-checkbox-checked.mat-success {

            .mat-checkbox-background {
                background: $success-color;
            }

        }

        &.mat-border-checkbox {

            .mat-checkbox-frame {
                border-color: $title-text-dark-color;
            }

            &.mat-checkbox-checked {

                .mat-checkbox-checkmark {
                    @include image-active-dark-color();
                }

            }

        }

        &.mat-checkbox-disabled {
            cursor: not-allowed;
        }

    }

    // ------------------------------------End------------------------------------------------------------------


    // -----------------------------------Radio Button----------------------------------------------------------

    .mat-radio-label {
        margin-right: 16px;
    }

    .mat-radio-button.mat-accent {

        &.mat-radio-checked {

            .mat-radio-inner-circle {
                background-color: $primary-color;
            }

            .mat-radio-outer-circle {
                border-color: $primary-color;
            }

        }

    }

    // ------------------------------------End------------------------------------------------------------------


    // -----------------------------------Select Box------------------------------------------------------------


    // ------------------------------------End------------------------------------------------------------------


    // -----------------------------------Auto Complete---------------------------------------------------------


    // ------------------------------------End------------------------------------------------------------------


    // -----------------------------------Menu------------------------------------------------------------------


    // ------------------------------------End------------------------------------------------------------------


    // -----------------------------------Chipset---------------------------------------------------------------


    // ------------------------------------End------------------------------------------------------------------

}


// -----------------------------------Dialog Box------------------------------------------------------------

.cdk-global-overlay-wrapper {
    background: rgba(0, 0, 0, 0.30);
    pointer-events: visible;

    .cdk-overlay-pane {

        .mat-dialog-container {
            padding: 0;
            border-radius: 0;
            position: relative;

            .mat-dialog-title {
                color: $white-color;
                font-size: 16px;
                font-weight: 500;
                font-family: 'Roboto';
                padding: 10px 15px;
                margin: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                letter-spacing: 0.5px;
                background: transparent linear-gradient(268deg, $primary-color 0%, $title-text-color 100%) 0% 0% no-repeat padding-box;
            }

            .mat-dialog-actions {
                width: 100%;
                min-height: auto;
                padding: 0;
                margin: 0;
                justify-content: space-between;

                .mat-button-base {
                    min-width: 48%;
                    height: 42px;
                    line-height: 42px;
                    font-size: 14px;
                    font-weight: 500;
                    padding: 0 24px;
                    border-radius: 0;
                    letter-spacing: 0.5px;
                }

            }

        }

        &.confirm-dialog-wrapper {
            width: 550px;

            .mat-dialog-container {
                overflow: visible;
            }

        }

        &.sm-dialog-wrapper {
            width: 570px;
            height: 100%;
        }

        &.md-dialog-wrapper {
            width: 640px;
            height: 100%;
        }

        .dialog-content-wrapper {
            width: 100%;
            padding: 20px;
            position: relative;
        }

        .form-dialog-wrapper {
            width: 100%;
            height: calc(100% - $dialog-header-height);
            position: relative;

            form {
                width: 100%;
                height: 100%;
            }

            .dialog-content-wrapper {
                height: calc(100% - $dialog-action-height);
                @include scroll-style();

                &.overflow-visible {
                    overflow: visible !important;
                }

            }

            .mat-dialog-actions {
                width: 100%;
                padding: 20px;
                box-sizing: border-box;

                .mat-flat-button {
                    @include button-style(48%, 48px);
                }

            }

        }

    }

}

// ------------------------------------End------------------------------------------------------------------