@import 'theme-variables';
@import 'common-styles';

mention-list {

    ul {
        padding: 0 !important;
        margin: 0 !important;
        position: relative !important;
        @include scroll-style();
    }

    li {

        .mention-wrapper {
            width: 100%;
            font-family: 'Roboto';
            padding: 2px 0;
            margin-bottom: 2px;

            img {
                width: 30px;
                height: 30px;
                border-radius: 50%;
            }

            .mention-label {
                color: $text-dark-color;
                font-size: 14px;
                font-weight: 500;
                padding-left: 10px;
                letter-spacing: 0.5px;
            }

        }

        &.active a,
        &:hover a {
            background-color: $grid-header-color !important;
            border-color: $grid-header-color;

            .mention-wrapper {

                .mention-label {
                    color: $grid-header-text-color !important;
                }

            }

        }

    }

}

.mceEditable {
    background-color: yellow;
}

.ques-text {
    color: #870a87;
}

.gen-rule,
.temp-rule,
.spec-rule,
.leadline-spec-rule,
.end-gen-rule,
.end-temp-rule,
.end-spec-rule,
.end-leadline-spec-rule {
    color: blue;
}

.spec-text,
.spec-under-rule,
.lead-spec-under-rule {
    color: red;
}

.mention-tag-text {
    color: #365899;
    font-family: 'Roboto';
    font-size: 14px;
}

.field-text {
    color: #09c796;
}

.mce-pagebreak {
    border: 1px ridge #c1c1c1;
    cursor: default;
    display: block;
    height: 5px;
    page-break-before: always;
    width: 100%;
    margin: 20px 0px 20px -50px;
    padding-left: 56px;
}

.error-field {

    .tox-tinymce {
        border: 1px solid $error-field-color !important;
    }

}

.text-box-editor {

    .tox-tinymce {
        height: 120px !important;
    }

}

.tox-tinymce {
    font-size: 13px !important;
    font-family: 'Roboto' !important;
    border-radius: 0 !important;
    border: 1px solid $border-color !important;
    background-color: $input-field-bg-color !important;

    .tox-editor-header {
        display: none !important;
    }

    iframe.tox-edit-area__iframe {
        @include scroll-style();
        background-color: transparent !important;

        body {
            color: $text-dark-color;
            font-family: 'Roboto';
            font-size: 14px;
            font-weight: 400;
            letter-spacing: 0.5px;
        }

    }

}

#tinymce {
    color: $text-dark-color;
    font-family: 'Roboto';
    font-size: 14px;
}

body,
.tiny-body-wrapper,
.mce-content-body {
    color: $text-dark-color;
    font-family: 'Roboto';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.5px;
}