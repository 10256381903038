$primary-color: #34B4E5;
$secondary-color: #E8E8E8;
$btn-disabled-color: #C4C4C4;
$title-text-color: #005287;
$title-text-medium-color: #025086;
$title-text-dark-color: #01263D;
$heading-text-color: #5F5F5F;
$text-color: #999999;
$text-thin-color: #B5B6BF;
$text-light-color: #9B9B9B;
$text-medium-color: #797C7D;
$text-dark-color: #021420;
$text-bold-color: #707070;
$success-color: #6AC980;
$error-color: #FF9191;
$warning-color: #EFA71C;
$border-color: #E7E8F2;
$input-field-bg-color: #F8FAFC;
$error-field-color: #FF0000;
$tile-color: #F5FAFF;
$scroll-thumb-color: #C9C9C9;
$side-nav-bar-color: #D9F5FE;
$side-nav-bar-hover-color: #F6F9FF;
$layout-color: #F7FBFF;
$link-color: #0C70D8;
$chip-list-hover-color: #E1E1E1;
$no-record-found-color: #EBEBEB;
$black-color: #000;
$white-color: #FFF;
$grey-color: #CCC;
$date-text-color: #55759A;
$grid-header-color: #C9EAFF;
$grid-header-text-color: #004978;
$grid-group-header-color: #F5F8FA;
$grid-drag-column-color: #F5F5F5;
$grid-page-number-color: #2B2B2B;
$nested-grid-header-color: #809BB8;
$details-bg-color: #FAFBFC;
$progressbar-border-color: #E7EAED;
$progressbar-background: #DEF2FF;
$logs-bg-color: #F7F7F7;
$deactivated-color: #F5F5F5;
$deactivated-text-color: #8f8f8f;
$lds_updated_color: #dddddd;
$mat-tab-background-color: #E7F1FC;
$mat-tab-border-color: #D9D7D7;
$mat-tab-label-color: #6F7070;
$header-bar-height: 73px;
$dialog-header-height: 60px;
$dialog-action-height: 88px;
$expansion-wrapper: 650px;
$expansion-header-wrapper: 50px;
$archive-color: #ff8686;
$daily-bg-color: #46A3F7;
$weekly-bg-color: #ED7946;
$monthly-bg-color: #8183F0;
